import { GetHeartbeatPort, HeartbeatDTO } from '../core/heartbeat';

import connectHttpClient from './connectHttpClient';

const fetchHeartbeat: GetHeartbeatPort = async () => {
  const response = await connectHttpClient.request<HeartbeatDTO>({
    url: 'heartbeat',
    method: 'GET',
  });

  return response.data;
};

export default fetchHeartbeat;
